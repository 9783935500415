import { Scene, Color, PerspectiveCamera, WebGLRenderer, PointsMaterial, Points, TextureLoader, MeshBasicMaterial, Mesh, SphereGeometry, LineBasicMaterial, EllipseCurve, BufferGeometry, Line, Raycaster, Vector2, Vector3, BackSide, RingGeometry, Float32BufferAttribute, PointLight } from 'three';

import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DObject, CSS2DRenderer } from './CSS2DRenderer.js';
import * as TWEEN from '@tweenjs/tween.js';
import sunImage from '../assets/sun2.jpg';
import mercuryImage from '../assets/mercury1.jpg';
import venusImage from '../assets/venus1.jpg';
import earthImage from '../assets/earth1.jpg';   
import moonImage from '../assets/moon.png'
import marsImage from '../assets/mars1.jpg';
import jupiterImage from '../assets/jupiter1.jpg';
import saturnImage from '../assets/saturn1.jpg';
import uranusImage from '../assets/uranus1.jpg';
import neptuneImage from '../assets/neptune1.jpg';
import plutoImage from '../assets/pluto1.jpg';
import pxImage from '../assets/stars_milky_way.jpg';
import asteroidImage2 from '../assets/asteroid3.png';
import lensTexure from '../assets/lensflare.jpg'
import lensTexure0 from '../assets/lensTexture0.png'

const LOG_BASE = 0.01;


const placeInput = document.getElementById("placeInput");
const suggestionsContainer = document.getElementById("suggestionsContainer");
const liveIcon = document.getElementById("liveIcon");
const select_tag = document.getElementById("select_tag");
const options = document.getElementById("options");
let active = 'Live';
let timeoutId;

select_tag.addEventListener('click', function () {
    console.log('................', options.style.display);
    options.style.display = options.style.display == 'none' ? 'block' : 'none';
});

const image_live_status = document.getElementById('live_status');
const image_solar_status = document.getElementById('solar_status');
const image_form_status = document.getElementById('form_status');
const form_close = document.getElementById('form_close');
const heading_text = document.getElementById('heading_text');

function setActiveOption(option) {
    active = option;
    updateImageSrc();
}

image_live_status.addEventListener('click', function () {
    active = 'Live';
    heading_text.innerText = 'Live Chart'
    updateImageSrc();
    document.getElementById("myForm").style.display = "none";
    liveIcon.style.display = "block";
    planetPositionData = null;
    adjustLabelHeights();
    setupSolarSystem();
    setupZodiacWithPlanets();
    const starTexture = loader.load(pxImage, function () {
        const starSphere = new THREE.Mesh(
            new THREE.SphereGeometry(15000, 32, 32),
            new THREE.MeshBasicMaterial({
                map: starTexture,
                side: THREE.BackSide
            })
        );
        scene.add(starSphere);

    }, undefined, function (error) {
        console.error('Error loading texture:', error);
    });
});

image_solar_status.addEventListener('click', function () {
    active = 'Solar';
    heading_text.innerText = 'Solar System'
    updateImageSrc();
    document.getElementById("myForm").style.display = "none";
});

image_form_status.addEventListener('click', function () {
    heading_text.innerText = 'My Chart'
    active = 'Form';
    updateImageSrc();
    const storedFormData = localStorage.getItem('formData');
    const dateInput = document.getElementById("dateInput");
    const timeInput = document.getElementById("timeInput");
    // const timezone = document.getElementById("timezoneInput").value;
    const latitude = document.getElementById("latitudeInput");
    const longitude = document.getElementById("longitudeInput");
    const place = document.getElementById("placeInput");
    const name_input = document.getElementById("name_input");

    if (storedFormData) {
        const formData = JSON.parse(storedFormData);
        name_input.value = formData?.name ? formData?.name : '';
        dateInput.value = formData?.dateInput ? formData?.dateInput : '';
        timeInput.value = formData?.timeInput ? formData?.timeInput : '';
        // timezone.value = formData.timezone;
        latitude.value = formData?.latitude ? formData?.latitude : '';
        longitude.value = formData?.longitude ? formData?.longitude : '';
        place.value = formData?.place ? formData?.place : '';
    }
});


const updateImageSrc = () => {
    if (active === 'Live') {
        heading_text.innerText = 'Live Chart'
        image_live_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/434ed66f-3b6c-436b-6032-6f916778c400/public';
    } else {
        image_live_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/5e8ca75f-42b9-47cf-afe6-a2c83020ab00/public';
    }
    if (active === 'Solar') {
        heading_text.innerText = 'Solar System'
        image_solar_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/ee47d18d-a680-45ad-0c92-382703136200/public'
    } else {
        image_solar_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/1d3e5448-ec25-47b5-10cf-3421acbf8300/public'
    }
    if (active === 'Form') {
        heading_text.innerText = 'My Chart'
        image_form_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/93d1c09e-2f2f-4ad0-5045-292eeab53d00/public';
        openForm();
    } else {
        image_form_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/b4c73361-cc1b-4f1e-2678-8dd5d8f32800/public'
    }
};

form_close.addEventListener('click', function () {
    document.getElementById("myForm").style.display = "none";
    active = 'Live';
    updateImageSrc();
})

placeInput.addEventListener("input", async (event) => {
    clearTimeout(timeoutId); // Clear any existing timeout
    suggestionsContainer.innerHTML = ""; // Clear previous suggestions

    const searchTerm = event.target.value;
    if (searchTerm.length >= 3) {
        // Wait for 2 seconds before making the API call
        timeoutId = setTimeout(async () => {
            const places = await fetchPlaces(searchTerm);
            places.forEach(place => {
                const suggestion = document.createElement("div");
                suggestion.textContent = place.place;
                suggestion.dataset.place = place.place; // Store place name in dataset
                suggestion.dataset.latitude = place.latitude; // Store latitude in dataset
                suggestion.dataset.longitude = place.longitude; // Store longitude in dataset
                suggestion.classList.add("suggestion");
                suggestionsContainer.appendChild(suggestion);
            });
        }, 1000);
    }
});

// Function to handle suggestion click
function handleSuggestionClick(place, latitude, longitude) {
    // Set the input text to the selected place
    document.getElementById("placeInput").value = place;

    // Set latitude and longitude in their respective input fields
    document.getElementById("latitudeInput").value = latitude;
    document.getElementById("longitudeInput").value = longitude;

    // Hide the suggestion container
    document.getElementById("suggestionsContainer").style.display = "none";
}

suggestionsContainer.addEventListener("click", function (event) {
    if (event.target.classList.contains("suggestion")) {
        console.log('......', event.target?.dataset)
        const place = event.target.dataset.place;
        const latitude = event.target.dataset.latitude;
        const longitude = event.target.dataset.longitude;
        handleSuggestionClick(place, latitude, longitude);
    }
});


async function fetchPlanetDataFromAPI() {
    const response = await fetch('https://backend.astrochalit.com/solarplanets');
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return await response.json();
}


// Basic setup
const scene = new Scene();
scene.background = new Color(0x000022);  // A deep blue color
const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

const renderer = new WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

const labelRenderer = new CSS2DRenderer();
labelRenderer.setSize(window.innerWidth, window.innerHeight);
labelRenderer.domElement.style.pointerEvents = 'none';
labelRenderer.domElement.style.position = 'absolute';
labelRenderer.domElement.style.top = '0px';
document.body.appendChild(labelRenderer.domElement);

const controls = new OrbitControls(camera, renderer.domElement);
controls.enabled = true;
controls.minDistance = 1;  // The minimum zoom level (or closest approach)
controls.maxDistance = 5000;  // The maximum zoom level (or farthest distance)
controls.enablePan = true; //If you want to disable panning (moving the camera up/down or left/right without rotating)
// If you want to restrict the vertical rotation (so you can't flip the camera upside-down for instance)
controls.minPolarAngle = 0;  // radians
controls.maxPolarAngle = Math.PI;  // radians
//If you want smoother transitions when moving the camera
controls.enableDamping = true;
controls.dampingFactor = 0.05;
controls.rotateSpeed = 1.0;
controls.zoomSpeed = 1.2;
controls.panSpeed = 0.8;


// Texture loader for planet images and background
const loader = new TextureLoader(); 

// Updated Celestial Body (Planet) Class
class CelestialBody {
    constructor(name, insights, imagePath, size, weight, speed, distanceFromSun) {
        this.name = name;
        this.insights = insights;
        this.size = size; // Diameter of the planet
        this.weight = weight; // Mass of the planet
        this.speed = speed; // Orbital speed
        this.distanceFromSun = distanceFromSun; // Average distance from the sun

        const texture = loader.load(imagePath);
        const material = new MeshBasicMaterial({ map: texture });
        this.mesh = new Mesh(new SphereGeometry(1, 32, 32), material);

        this.mesh.userData = { 
            name: this.name, 
            insights: this.insights, 
            size: this.size, 
            weight: this.weight, 
            speed: this.speed, 
            distanceFromSun: this.distanceFromSun 
        };
    }
}

const celestialBodies = {
    sun: new CelestialBody("Sun", "The Sun is the star at the center of the Solar System.", sunImage, "1.39 million km", "1.989 × 10^30 kg", "Static", "Center of Solar System"),
    mercury: new CelestialBody("Mercury", "Mercury is the smallest and innermost planet in the Solar System.", mercuryImage, "4,880 km", "3.3 × 10^23 kg", "47.87 km/s", "57.91 million km"),
    venus: new CelestialBody("Venus", "Venus is the second planet from the Sun.", venusImage, "12,104 km", "4.87 × 10^24 kg", "35.02 km/s", "108.2 million km"),
    earth: new CelestialBody("Earth", "Earth is the third planet from the Sun and the only astronomical object known to harbor life.", earthImage, "12,742 km", "5.972 × 10^24 kg", "29.78 km/s", "149.6 million km"),
    moon: new CelestialBody("Moon", "The Moon is Earth's only natural satellite.", moonImage, "3,474 km", "7.35 × 10^22 kg", "1.022 km/s", "384,400 km"),
    mars: new CelestialBody("Mars", "Mars is the fourth planet from the Sun and the second-smallest planet in the Solar System.", marsImage, "6,779 km", "6.39 × 10^23 kg", "24.077 km/s", "227.9 million km"),
    jupiter: new CelestialBody("Jupiter", "Jupiter is the fifth planet from the Sun and the largest in the Solar System.", jupiterImage, "139,820 km", "1.898 × 10^27 kg", "13.07 km/s", "778.5 million km"),
    saturn: new CelestialBody("Saturn", "Saturn is the sixth planet from the Sun and the second-largest in the Solar System.", saturnImage, "116,460 km", "5.68 × 10^26 kg", "9.68 km/s", "1.42 billion km"),
    uranus: new CelestialBody("Uranus", "Uranus is the seventh planet from the Sun.", uranusImage, "50,724 km", "8.68 × 10^25 kg", "6.8 km/s", "2.87 billion km"),
    neptune: new CelestialBody("Neptune", "Neptune is the eighth and farthest known planet from the Sun in the Solar System.", neptuneImage, "49,244 km", "1.02 × 10^26 kg", "5.43 km/s", "4.5 billion km"),
    pluto: new CelestialBody("Pluto", "Pluto is a dwarf planet in the Kuiper belt.", plutoImage, "2,377 km", "1.31 × 10^22 kg", "4.67 km/s", "5.91 billion km")
};


// Modifying the sizes based on provided dimensions
celestialBodies.sun.mesh.scale.set(2.0, 2.0, 2.0);
celestialBodies.mercury.mesh.scale.set(1.0, 1.0, 1.0);
celestialBodies.venus.mesh.scale.set(1.0, 1.0, 1.0);
celestialBodies.earth.mesh.scale.set(1.0, 1.0, 1.0);
celestialBodies.moon.mesh.scale.set(0.2, 0.2, 0.2);
celestialBodies.mars.mesh.scale.set(1.0, 1.0, 1.0);
celestialBodies.jupiter.mesh.scale.set(1.5, 1.5, 1.5);
celestialBodies.saturn.mesh.scale.set(1.4, 1.4, 1.4);
celestialBodies.uranus.mesh.scale.set(1.0, 1.0, 1.0);
celestialBodies.neptune.mesh.scale.set(1.0, 1.0, 1.0);
celestialBodies.pluto.mesh.scale.set(1.0, 1.0, 1.0);

// Setting positions for the celestial bodies using more accurate scaled distances
celestialBodies.sun.mesh.position.set(0, 0, 0); // Sun remains at the center
celestialBodies.mercury.mesh.position.set(20.79, 0, 0); // ~0.39 AU
celestialBodies.venus.mesh.position.set(40.82, 0, 0); // ~0.72 AU
celestialBodies.earth.mesh.position.set(70, 0, 0);     // ~1 AU
celestialBodies.mars.mesh.position.set(104.79, 0, 0);   // ~1.52 AU
celestialBodies.jupiter.mesh.position.set(154.83, 0, 0);// ~5.2 AU
celestialBodies.saturn.mesh.position.set(284.67, 0, 0);// ~9.58 AU
celestialBodies.uranus.mesh.position.set(574.1, 0, 0); // ~19.22 AU
celestialBodies.neptune.mesh.position.set(898.5, 0, 0);// ~30.05 AU
celestialBodies.pluto.mesh.position.set(1192.3, 0, 0);  // ~39.53 AU

const celestialMeshes = [];
// Modify planet creation by adding the planet name
for (let body in celestialBodies) {
    const mesh = celestialBodies[body].mesh;
    scene.add(mesh);
    celestialMeshes.push(mesh);
}


// Add celestial bodies to the scene
for (let body in celestialBodies) {
    scene.add(celestialBodies[body].mesh);
}


// Load the star texture
const starTexture = loader.load(pxImage); // Replace with your star texture path

// Increase the size of the starry background
const starSphere = new Mesh(
    new SphereGeometry(5000, 64, 64), // Increase the size here
    new MeshBasicMaterial({
        map: starTexture,
        side: BackSide
    })
);
scene.add(starSphere);

// Adjust the camera's far clipping plane to accommodate the larger starry background
camera.far = 20000; // Adjust this value as per your requirement
camera.near = 1;
camera.updateProjectionMatrix(); // Necessary after changing camera properties

// Interaction (Raycasting)
const raycaster = new Raycaster();
const mouse = new Vector2();

// On Mouse Move
function onMouseMove(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
}

// function onMouseClick(event) {
//     raycaster.setFromCamera(mouse, camera);
//     const intersects = raycaster.intersectObjects(celestialMeshes);  // Intersect only with celestialMeshes
//     if (intersects.length > 0) {
//         const firstIntersect = intersects[0];
//         if (firstIntersect.object.userData) {
//             const planetName = firstIntersect.object.userData.name;
//             const planetDetails = firstIntersect.object.userData.insights;
//             console.log("Planet clicked:", planetName); // Debug log

//             if (planetName && planetDetails) {
//                 document.getElementById('planet-name').textContent = planetName;
//                 document.getElementById('planet-details').textContent = planetDetails;
                
//                 // Show the planet-info div
//                 console.log("Attempting to show planet-info div");
//                 document.getElementById('planet-info').style.display = "block";
        
//                 const targetPosition = firstIntersect.object.position.clone();
//                 moveCameraToPosition(targetPosition, 500);
//             } else {
//                 // Hide the planet-info div if no planet details are available
//                 document.getElementById('planet-info').style.display = "none";
//             }
//         }
//     }
// }

function onMouseClick(event) {
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(celestialMeshes);  // Intersect only with celestialMeshes
    if (intersects.length > 0) {
        const firstIntersect = intersects[0];
        if (firstIntersect.object.userData) {
            const planetName = firstIntersect.object.userData.name;
            const planetDetails = firstIntersect.object.userData.insights;
            console.log("Planet clicked:", planetName); // Debug log

            if (planetName && planetDetails) {
                document.getElementById('planet-name').textContent = planetName;
                
                // Combine the insights and other details
                let combinedDetails = `
                    <strong>Insights:</strong> ${planetDetails}<br>
                    <strong>Size:</strong> ${firstIntersect.object.userData.size}<br>
                    <strong>Weight:</strong> ${firstIntersect.object.userData.weight}<br>
                    <strong>Speed:</strong> ${firstIntersect.object.userData.speed}<br>
                    <strong>Distance from Sun:</strong> ${firstIntersect.object.userData.distanceFromSun}
                `;
            
                document.getElementById('planet-details').innerHTML = combinedDetails;  // Using innerHTML because we're adding HTML tags

                // Show the planet-info div
                document.getElementById('planet-info').style.display = "block";

                const targetPosition = firstIntersect.object.position.clone();
                moveCameraToPosition(targetPosition, 500, planetName.toLowerCase());
            } else {
                // Hide the planet-info div if no planet details are available
                document.getElementById('planet-info').style.display = "none";
            }
        }
    }   
}


// Attach mouse movement and click event listeners to the window
window.addEventListener('mousemove', onMouseMove, false);
window.addEventListener('click', onMouseClick, false);

window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.setSize(window.innerWidth, window.innerHeight);
});

// Event listener for planet links
document.addEventListener("DOMContentLoaded", function() {
    setActiveOption('Solar');
    document.querySelectorAll('.planet-link').forEach(link => {
        link.addEventListener('click', function(event) {
            event.preventDefault();  // Prevent default anchor link behavior
            const planetKey = this.getAttribute('data-planet');
            if (celestialBodies[planetKey]) {
                const targetPosition = celestialBodies[planetKey].mesh.position;
                moveCameraToPosition(targetPosition, 1500, planetKey);
                
                // Display the planet's details card
                const planetData = celestialBodies[planetKey].mesh.userData;
                document.getElementById('planet-name').textContent = planetData.name;
                
                // Combine the insights and other details
                let combinedDetails = `
                    <strong>Insights:</strong> ${planetData.insights}<br>
                    <strong>Size:</strong> ${planetData.size}<br>
                    <strong>Weight:</strong> ${planetData.weight}<br>
                    <strong>Speed:</strong> ${planetData.speed}<br>
                    <strong>Distance from Sun:</strong> ${planetData.distanceFromSun}
                `;
                
                document.getElementById('planet-details').innerHTML = combinedDetails;  // Using innerHTML because we're adding HTML tags

                // Show the planet-info div
                document.getElementById('planet-info').style.display = "block";
            }
        });
    });
});


document.addEventListener('keydown', function(event) {
    if (event.key === 'r' || event.key === 'R') {
        attachedToPlanet = null;
        // controls.target.set(0, 0, 0);  // Reset target to the center of the scene
        controls.enableRotate = true;  // Allow free rotation
    }
});


const CAMERA_DISTANCE = 0;  // The constant distance from the planet when the camera focuses on it.
let currentTarget = null; // Initially, no planet is in focus
let focusedPlanet = null;

function moveCameraToPosition(targetPosition, duration = 1000, planetKey) {    
    const startTime = performance.now();
    const startPos = camera.position.clone();
    const targetWithDistance = targetPosition.clone().add(targetPosition.clone().normalize().multiplyScalar(CAMERA_DISTANCE));
    currentTarget = celestialBodies[planetKey].mesh;
    attachedToPlanet = planetKey;
    function animate() {
        const elapsed = performance.now() - startTime;
        if (elapsed < duration) {
            const alpha = elapsed / duration;
            camera.position.lerpVectors(startPos, targetWithDistance, alpha);
            camera.lookAt(targetPosition);
            requestAnimationFrame(animate);
        } else {
            camera.position.copy(targetWithDistance);
            camera.lookAt(targetPosition);
        }
    }

    // Update the cameraOffset based on the final position
    cameraOffset = camera.position.clone().sub(targetPosition);

    // Set the focused planet
    focusedPlanet = celestialBodies[planetKey];

    animate();
}


camera.position.z = 50; // Adjusted to see more of the solar system

// Define a rotation speed for each celestial body
const rotationSpeeds = {
    mercury: 0.0005,
    venus: 0.0005,
    earth: 0.001,
    moon: 0.0005, // Revolving around Earth
    mars: 0.0005,
    jupiter: 0.0005,
    saturn: 0.0005,
    uranus: 0.0005,
    neptune: 0.0005,
    pluto: 0.0005
};

// Define angles for each celestial body
const angles = {
    mercury: 0,
    venus: 2,
    earth: 4.5,
    moon: 0,  // Relative to Earth
    mars: 1,
    jupiter: 5.5,
    saturn: 2,
    uranus: 5,
    neptune: 3,
    pluto: 5.7
};

// Add sun light effect
const sunLight = new PointLight(0xffffff, 1.5, 5000);  // white light, intensity, and a default distance that affects all objects
sunLight.position.set(celestialBodies.sun.mesh.position.x, celestialBodies.sun.mesh.position.y, celestialBodies.sun.mesh.position.z);
scene.add(sunLight);

// Adding lens flare to the sun
const textureFlare0 = loader.load(lensTexure);
const textureFlare3 = loader.load(lensTexure0);

const lensflare = new Lensflare();
lensflare.addElement(new LensflareElement(textureFlare0, 10, 10)); // Large flare at the sun
lensflare.addElement(new LensflareElement(textureFlare3, 100, 0.01)); // Smaller flare close to the sun
lensflare.addElement(new LensflareElement(textureFlare3, 60, 0.02)); // Smaller flare close to the sun
// lensflare.addElement(new LensflareElement(textureFlare3, 80, 0.2));
// lensflare.addElement(new LensflareElement(textureFlare3, 100, 0.3));

sunLight.add(lensflare);

// Initialize an object to keep track of created labels
let labelMeshes = {};  // Change from const to let

// Updated createLabel function
function createLabel(name, parentMesh) {
    // Check if a label has already been created for this body
    if (!labelMeshes[name]) {
        // Create the label element
        const element = document.createElement('div');
        element.textContent = name;
        element.className = 'label';  // Add a class for CSS styling

        // Create a CSS2DObject for the label
        const label = new CSS2DObject(element);

        // Add the label to the parentMesh
        parentMesh.add(label);

        // Mark this label as created
        labelMeshes[name] = label;

        console.log(`Label for ${name} is created.`);
    }
}

// Updated clearLabels function
function clearLabels() {
    for (let planet in labelMeshes) {
        const label = labelMeshes[planet];
        if (label) {
            // Remove the label from its parent mesh
            label.parent.remove(label);
        }
    }

    // Clear the labelMeshes object
    labelMeshes = {};  // Reset to an empty object
}

function updateLabels() {
    // Threshold for camera FOV to determine label visibility
    const fovThreshold = 110;  // Example threshold value, adjust based on your camera setup

    for (let body in celestialBodies) {
        const planet = celestialBodies[body];
        const label = labelMeshes[body];  // Access the label mesh

        if (label) {
            // Adjusting Moon's label visibility based on camera FOV
            if (body === "moon") {
                if (camera.fov > fovThreshold) {
                    label.element.style.display = 'none';
                } else {
                    label.element.style.display = '';
                    // Calculate opacity based on FOV, more opaque as we zoom in more
                    const opacity = (fovThreshold / camera.fov) - 2;
                    label.element.style.opacity = Math.min(1, Math.max(0, opacity)).toFixed(2);
                }
            } else {
                // Earth and other planets always show their labels
                label.element.style.display = '';
                label.element.style.opacity = 1; // Full visibility
            }

            // Set the label height slightly above the planet
            const labelHeight = planet.mesh.geometry.parameters.radius + 1;
            label.position.set(0, labelHeight, 0);
        }
    }
}


// Array of asteroid image paths
const asteroidImages = [asteroidImage2];

// Function to load all asteroid textures
function loadAsteroidTextures(imagePaths, loader) {
    const promises = imagePaths.map(path => {
        return new Promise((resolve, reject) => {
            loader.load(path, resolve, undefined, reject);
        });
    });
    return Promise.all(promises);
}


// Store loaded textures
let asteroidTextures = [];

// Call this function at the beginning of your script or in a setup function
loadAsteroidTextures(asteroidImages, loader).then(textures => {
    asteroidTextures = textures;
    createAsteroidBelt(); // Ensure this is called after textures are loaded
});

async function createAsteroidBelt() {
    const liveData = await fetchPlanetDataFromAPI();
    const marsPosition = liveData.mars.distance * 100;
    const jupiterPosition = liveData.jupiter.distance * 100;

    // Calculate the midpoint between Mars and Jupiter for the belt's center
    const asteroidBeltRadius = (marsPosition + jupiterPosition) / 2;
    const asteroidWidth = (jupiterPosition - marsPosition) / 2; // Half the distance between Mars and Jupiter

    const thicknessFactor = 1.7; // Increase this factor to spread the belt wider
    const asteroidVertices = [];
    for (let i = 0; i < 10000; i++) {
        const theta = 2 * Math.PI * Math.random();
        // Modify the range of randomness to increase the belt's thickness
        const r = asteroidBeltRadius + (Math.random() * asteroidWidth * thicknessFactor - (asteroidWidth * thicknessFactor / 2));
        asteroidVertices.push(
            r * Math.cos(theta),
            Math.random() * 10 - 5,  // Increase vertical variation for more pronounced thickness
            r * Math.sin(theta)
        );
    }

    const asteroidGeometry = new BufferGeometry();
    asteroidGeometry.setAttribute('position', new Float32BufferAttribute(asteroidVertices, 3));

    const texture = asteroidTextures[Math.floor(Math.random() * asteroidTextures.length)];
    const asteroidMaterial = new PointsMaterial({
        color: 0xffffff,
        size: 0.9,  // Increase size for better visibility
        map: texture,
        transparent: true,
        depthTest: true  // Ensuring asteroids are visible at all depths
    });

    const asteroid = new Points(asteroidGeometry, asteroidMaterial);
    scene.add(asteroid);
}

// function addEarthMoon(planetsData) {
//     // Constants for conversion and scaling
//     const SCALE_FACTOR = 100;  // Example scale factor for visual representation

//     // Calculate positions based on heliocentric longitudes and distances
//     const earthLongitudeRadians = planetsData.moon.longitude * (Math.PI / 180); // Use Moon's longitude for Earth
//     const earthDistanceFromSun = (planetsData.sun.distance + 0.003) * SCALE_FACTOR;  // Adjusted Earth distance using Sun's distance plus an additional offset
//     const moonDistanceFromSun = (planetsData.moon.distance + planetsData.sun.distance) * SCALE_FACTOR; // Adjusted for visual representation

//     // Position Earth using the same longitude as Moon
//     celestialBodies.earth.mesh.position.set(
//         earthDistanceFromSun * Math.cos(earthLongitudeRadians),
//         0,  // Assuming the ecliptic plane for simplicity
//         earthDistanceFromSun * Math.sin(earthLongitudeRadians)
//     );

//     // Position Moon relative to Earth
//     const moonRelativeDistance = 0.1 * SCALE_FACTOR;  // Scaled moon distance from Earth for visual clarity
//     const relativeMoonLongitude = earthLongitudeRadians; // Since we are assuming Earth and Moon are at the same longitude

//     celestialBodies.moon.mesh.position.set(
//         celestialBodies.earth.mesh.position.x + moonRelativeDistance * Math.cos(relativeMoonLongitude),
//         0,  // Assuming the ecliptic plane for simplicity
//         celestialBodies.earth.mesh.position.z + moonRelativeDistance * Math.sin(relativeMoonLongitude)
//     );
// }

function positionPlanets(planetsData) {
    const SCALE_FACTOR = 100;  // Adjust this for the scale of your visualization

    // Position Moon first based on API data
    const moonDistanceFromSun = planetsData.moon.distance * SCALE_FACTOR;
    const moonLongitudeRadians = -(planetsData.moon.longitude * (Math.PI / 180));
    celestialBodies.moon.mesh.position.set(
        moonDistanceFromSun * Math.cos(moonLongitudeRadians),
        0,
        moonDistanceFromSun * Math.sin(moonLongitudeRadians)
    );
    scene.add(celestialBodies.moon.mesh);

    // Position Earth close to the Moon
    const earthDistanceFromSun = moonDistanceFromSun; // Place Earth at the same radius as Moon for simplicity
    const earthLongitudeRadians = moonLongitudeRadians + 0.003 * (Math.PI / 180);  // Small offset to distinguish Earth from Moon visually

    celestialBodies.earth.mesh.position.set(
        earthDistanceFromSun * Math.cos(earthLongitudeRadians),
        0,
        earthDistanceFromSun * Math.sin(earthLongitudeRadians)
    );
    scene.add(celestialBodies.earth.mesh);

    // Exclude list to prevent moving the Sun, Moon, and Earth again
    const excludeList = ["sun"];

    // Position other planets
    for (const [key, planetData] of Object.entries(planetsData)) {
        if (!excludeList.includes(key)) {
            const planet = celestialBodies[key];
            const longitudeRadians = -(planetData.longitude * (Math.PI / 180));  // Reverse the direction
            const distanceFromSun = planetData.distance * SCALE_FACTOR;
            planet.mesh.position.x = distanceFromSun * Math.cos(longitudeRadians);
            planet.mesh.position.z = distanceFromSun * Math.sin(longitudeRadians);
            scene.add(planet.mesh);
        }
    }
}

const orbitMaterials = new LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.5 });

// Function to add orbits using data from API
function addPlanetOrbits(planetsData) {
    const SCALE_FACTOR = 100;  // Same scale factor for consistency

    const orbitMaterials = new LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.5 });
    for (const [key, planetData] of Object.entries(planetsData)) {
        if (key !== 'sun') {  // Excluding Sun since it does not orbit another body
            let orbitRadius = planetData.distance * SCALE_FACTOR;
            if (key === 'earth') {
                orbitRadius = (planetsData.moon.distance + 0.003) * SCALE_FACTOR;  // Custom Earth orbit
            }

            const ellipse = new EllipseCurve(
                0, 0,  // Center of the ellipse at the origin
                orbitRadius, orbitRadius,  // X and Y radius set to the distance from the sun
                0, 2 * Math.PI,  // Start angle and end angle
                false, 0  // No angle offset
            );

            const ellipsePoints = ellipse.getPoints(100);
            const ellipseGeometry = new BufferGeometry().setFromPoints(ellipsePoints);
            const ellipseLine = new Line(ellipseGeometry, orbitMaterials);
            ellipseLine.rotation.x = Math.PI / 2;
            scene.add(ellipseLine);
        }
    }
}

let cameraOffset = new Vector3(0, 0, CAMERA_DISTANCE);
let attachedToPlanet = null;
let moonAngle = 0;  // Starting angle for the Moon

function animate() {
    const orbitalSpeed = 0.0009;  // Control the speed of the Moon's orbit
    // console.log(`Camera Position - x: ${camera.position.x}, y: ${camera.position.y}, z: ${camera.position.z}`);

    // Update Moon's angle
    moonAngle += orbitalSpeed;

    // Update Moon's position based on the new angle
    const moonDistanceFromEarth = 0.03 * 100;  // Consistent with earlier setup
    celestialBodies.moon.mesh.position.x = celestialBodies.earth.mesh.position.x + moonDistanceFromEarth * Math.cos(moonAngle);
    celestialBodies.moon.mesh.position.z = celestialBodies.earth.mesh.position.z + moonDistanceFromEarth * Math.sin(moonAngle);

    // Planet self rotation
    for (let body in celestialBodies) {
        celestialBodies[body].mesh.rotation.y += 0.005;
    }

    if (currentTarget) {
        camera.lookAt(currentTarget.position);
    }

    // Update positions for revolving planets
    // for (let body in celestialBodies) {
    //     if (body !== 'sun' && body !== 'moon') {  // Sun doesn't revolve, Moon's revolution handled separately
    //         angles[body] += rotationSpeeds[body];
    //         const r = celestialBodies[body].mesh.position.distanceTo(new Vector3(0,0,0));
    //         celestialBodies[body].mesh.position.x = r * Math.cos(angles[body]);
    //         celestialBodies[body].mesh.position.z = r * Math.sin(angles[body]);
    //     }
    // }

    // Update label positions based on planet positions
    for (let body in celestialBodies) {
        const planet = celestialBodies[body];
        const label = labelMeshes[body];
        if (label) {
            label.position.set(0, planet.mesh.geometry.parameters.radius + 1, 0);
        }
    }

    // Update Moon's position to revolve around Earth in the animate function
    // const moonDistanceFromEarth = 3;
    // angles['moon'] += rotationSpeeds['moon'];
    // const moonXFromEarth = moonDistanceFromEarth * Math.cos(angles['moon']);
    // const moonZFromEarth = moonDistanceFromEarth * Math.sin(angles['moon']);
    // celestialBodies['moon'].mesh.position.x = celestialBodies['earth'].mesh.position.x + moonXFromEarth;
    // celestialBodies['moon'].mesh.position.z = celestialBodies['earth'].mesh.position.z + moonZFromEarth;

    // Update the controls
    controls.update();

    if (currentTarget) {
        // Calculate the new camera position relative to the current target
        let newCameraPosition = currentTarget.position.clone().add(cameraOffset);
        camera.position.lerp(newCameraPosition, 0.1);  // Smooth transition to the new position
        camera.lookAt(currentTarget.position);
    }

    if (attachedToPlanet) {
        const planetMesh = celestialBodies[attachedToPlanet].mesh;
        camera.position.set(planetMesh.position.x, planetMesh.position.y, planetMesh.position.z + 5);
        controls.target = planetMesh.position;
    }

    requestAnimationFrame(animate);
    updateLabels();


    if (focusedPlanet) {
        camera.lookAt(focusedPlanet.mesh.position);
    }

    labelRenderer.render(scene, camera);
    renderer.render(scene, camera);
}

let isSetupDone = false;

// Async function to setup the solar system with live data
async function setupSolarSystem() {

    if (isSetupDone) {
        // Clear existing labels if the setup has already been done
        clearLabels();
    }
    const liveData = await fetchPlanetDataFromAPI();

    for (let planet in liveData) {
        if (celestialBodies[planet]) {
            celestialBodies[planet].speed = `${liveData[planet].speed} km/s`;
            celestialBodies[planet].distanceFromSun = `${liveData[planet].distance} km`;

            // If the planet has a retrograde motion, annotate its name
            if (liveData[planet].speed < 0) {
                celestialBodies[planet].name += " (R)";
            }
        }
    }

    // Remove existing labels
    for (let planet in celestialBodies) {
        const bodyMesh = celestialBodies[planet].mesh;
        if (bodyMesh.userData.labelCreated) {
            const label = bodyMesh.children.find(child => child instanceof CSS2DObject);
            if (label) {
                bodyMesh.remove(label);
                bodyMesh.userData.labelCreated = false;  // Set labelCreated to false
            }
        }
    }

    // Add new labels
    for (let planet in celestialBodies) {
        const bodyMesh = celestialBodies[planet].mesh;
        if (!bodyMesh.userData.labelCreated) {
            createLabel(planet, bodyMesh);
        }
    }

    // As before, define and add the celestial bodies to the scene
    for (let body in celestialBodies) {
        scene.add(celestialBodies[body].mesh);
    }


    positionPlanets(liveData);
    addPlanetOrbits(liveData);
    // addEarthMoon(liveData);

    animate();
}

setupSolarSystem();

camera.position.set(129.2519753895677, 33.753174048400126, 165.44909318873133);  // Adjust Z to fit the scene scale
camera.lookAt(new Vector3(0, 0, 0));
